
.spectaculars-box{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: radial-gradient(circle, #101671, #0F0841);
  ::v-deep .el-scrollbar__wrap {
    overflow-x: hidden;
  }
  .spectaculars-header{
    display: flex;
    flex-direction: column;
    margin-top: 38px;
    .header-title{
      text-align: center;
      background-image: linear-gradient(180deg,#00f5ff,#00f5ff,#00baff);
      -webkit-background-clip:text;
      color: transparent;
      position: relative;
      span{
        font-size: 26px;
        //color: #3CC5EE;
        font-weight: bold;
        line-height: 1;
        display: inline-block;
        box-shadow: 0px 5px 91px 26px rgba(18, 72, 251, 0.6);
        background: rgba(18, 72, 251,0.3);
      }
    }
    .header-title-img{
      height: 48px;
      position: relative;
      display: flex;
      justify-content: center;
      .img-box{
        position: relative;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 2%;
        img{
          width: 100%;
          height: 48px;
        }
        .dimensionality-class{
          position: absolute;
          left: 1.64%;
          display: flex;
          top: -39px;
          .dimensionality-item{
            width: 134px;
            height: 44px;
            line-height: 44px;
            text-align: center;
            color: #709EFB;
            text-shadow: 0 0 12px rgba(255,255,255,0.5);
            cursor: pointer;
            background-repeat: no-repeat;
            background-size: 100% 100%;
          }
          .dimensionalityItemActive{
            background-image: url("./../../assets/img/spectaculars/dimensionality-img.png");
            color: #fff;
          }
        }
        .quadrangle-look{
          font-size: 28px;
          position: absolute;
          left: 30px;
          bottom: 0;
          background-image: linear-gradient(180deg,#00f5ff,#00f5ff,#00baff);
          -webkit-background-clip:text;
          color: transparent;
          cursor: pointer;
          text-shadow: 0 0 12px rgba(255,255,255,0.4);
        }
        .quadrangle-set{
          position: absolute;
          right: 16px;
          top: -30px;
          font-size: 28px;
          background-image: linear-gradient(180deg,#00f5ff,#00f5ff,#00baff);
          -webkit-background-clip:text;
          color: transparent;
          cursor: pointer;
        }
        .look-pitch-on{
          box-sizing: border-box;
          padding: 0 2px 22px 22px;
          position: absolute;
          left: 80px;
          top: 22px;
          border: 2px solid #28D7FC;
          background: rgba(29,32,117,0.8);
          border-radius: 6px;
          width: 600px;
          z-index: 99;
          display: flex;
          .closeIcon{
            color: #28D7FC;
            font-size: 14px;
            cursor: pointer;
            position: absolute;
            right: 10px;
            top: 10px;
          }
          .mid-right-icon{
            position: absolute;
            left: calc(50% - 6px);
            top: 80px;
            display: flex;
            align-items: center;
            i{
              font-size: 14px;
              color: #09C1E1;
              &:nth-child(1){
                color: #2D6099;
              }
            }
          }
          .pitch-on-left,.pitch-on-right{
            overflow: hidden;
            width: 50%;
            display: flex;
            flex-direction: column;
            align-items: center;
            &.pitch-on-left{
              margin-right: 30px;
            }
            .left-icon, .right-icon{
              text-align: center;
              line-height: 1;
              margin: 22px 0 22px;
              i{
                margin-right: 20px;
                text-align: center;
                font-size: 24px;
                color: #28D7FC;
                text-shadow: 0 0 20px rgba(255,255,255,0.8);
              }
            }
            .left-list-scrollbar, .right-list-scrollbar{
              height: 340px;
              ::v-deep.el-scrollbar__wrap {
                overflow-x: hidden;
                //margin-right: 0!important;
              }
              ::v-deep.is-vertical{
                .el-scrollbar__thumb{
                  width: 7px!important;
                  background-color: #16D0FF!important;
                }
              }
            }
            .left-list{
              width: 236px;
              display: flex;
              flex-direction: column;
              align-items: center;
              margin-right: 20px;
              .left-list-item{
                width: 236px;
                line-height: 32px;
                height: 32px;
                color: #fff;
                font-size: 12px;
                border-radius: 2px;
                //border: 1px solid rgba(9, 183, 219, 0.17);
                text-align: center;
                box-shadow:rgba(11, 234, 235,0.8) 0px 0px 8px inset;
                margin-top: 10px;
                cursor: pointer;
                padding: 0 20px;
                box-sizing: border-box;
                span{
                  display: inline-block;
                  width: 100%;
                }
                &:first-of-type{
                  margin-top: 0;
                }
              }
              .listItemActive{
                background: #28D7FC;
              }
            }
            .right-list{
              margin-right: 20px;
              width: 236px;
              box-sizing: border-box;
              padding: 0 20px 20px;
              box-shadow:rgba(11, 234, 235,0.8) 0px 0px 8px inset;
              display: flex;
              flex-direction: column;
              min-height: 340px;
              .right-list-item{
                text-align: center;
                font-size: 12px;
                color: #fff;
                margin-top: 20px;
              }
            }
          }
        }
        .set-pitch-on-box{
          background: rgba(0,0,0,0.2);
          position: fixed;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 100;
          .set-pitch-on{
            box-sizing: border-box;
            border: 2px solid #28D7FC;
            background: rgba(29,32,117,0.8);
            border-radius: 6px;
            width: 600px;
            display: flex;
            flex-direction: column;
            padding: 22px 12px;
            ::v-deep.el-checkbox .is-checked .el-checkbox__inner{
              //border-radius: 50%;
              border-color: #09C1E1;
              background-color: #09C1E1;
              &::after{
                border-color: #1D2075;
              }
            }
            .set-pitch-top{
              display: flex;
              justify-content: space-between;
              span{
                padding-left: 24px;
                line-height: 30px;
                font-size: 16px;
                color: #fff;
                display: inline-block;
                width: 138px;
                height: 26px;
                background-image: url("./../../assets/img/spectaculars/set-pitch-img.png");
                background-repeat: no-repeat;
                background-size: 100% 100%;
                font-weight: bold;
              }
              i{
                color: #28D7FC;
                font-size: 14px;
                cursor: pointer;
              }
            }
            .all-check{
              margin-top:34px;
              ::v-deep.el-checkbox__label{
                color: #fff;
                font-size: 14px;
                font-weight: bold;
              }
            }
            .set-pitch-mid{
              display: flex;
              margin-top: 20px;
              position: relative;
              .mid-right-icon{
                position: absolute;
                left: calc(50% - 8px);
                top: 12px;
                display: flex;
                align-items: center;
                i{
                  font-size: 14px;
                  color: #09C1E1;
                  &:nth-child(1){
                    color: #2D6099;
                  }
                }
              }
              .pitch-on-left,.pitch-on-right{
                overflow: hidden;
                width: 50%;
                display: flex;
                flex-direction: column;
                align-items: center;
                &.pitch-on-left{
                  margin-right: 30px;
                  align-items: start;
                }
                .left-list-scrollbar, .right-list-scrollbar{
                  height: 340px;
                  ::v-deep.el-scrollbar__wrap {
                    overflow-x: hidden;
                  }
                  ::v-deep.is-vertical{
                    .el-scrollbar__thumb{
                      width: 7px!important;
                      background-color: #16D0FF!important;
                    }
                  }
                  ::v-deep.el-checkbox{
                    .el-checkbox__label{
                      display: none;
                    }
                  }
                }
                .left-list{
                  //width: 236px;
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  margin-right: 20px;
                  .left-list-item{
                    display: flex;
                    align-items: center;
                    //border: 1px solid rgba(9, 183, 219, 0.17);
                    margin-top: 10px;
                    box-sizing: border-box;
                    .text-overflow{
                      width: 230px;
                      display: inline-block;
                      line-height: 32px;
                      height: 32px;
                      color: #fff;
                      font-size: 12px;
                      border-radius: 2px;
                      text-align: center;
                      box-shadow:rgba(11, 234, 235,0.8) 0px 0px 8px inset;
                      box-sizing: border-box;
                      cursor: pointer;
                      padding: 0 20px;
                      margin-left: 8px;
                    }
                    &:first-of-type{
                      margin-top: 0;
                    }
                    ::v-deep.el-checkbox{
                      .el-checkbox__label{
                        display: none;
                      }
                    }
                  }
                  .listItemActive{
                    .text-overflow{
                      background: #28D7FC;
                    }

                  }
                }
                .right-list{
                  margin-right: 20px;
                  width: 236px;
                  box-sizing: border-box;
                  padding: 0 20px 20px;
                  box-shadow:rgba(11, 234, 235,0.8) 0px 0px 8px inset;
                  display: flex;
                  flex-direction: column;
                  min-height: 340px;
                  .right-list-item{
                    font-size: 12px;
                    color: #fff;
                    margin-left: 20px;
                  }
                }
              }
            }
            .set-pitch-footer{
              text-align: center;
              margin-top: 20px;
              .el-button{
                padding: 8px 35px;
                font-size: 14px;
              }
            }
          }
        }
      }
      .header-quadrangle{
        display: flex;
        position: absolute;
        left:calc(50% + 200px);
        top: -22px;
        .quadrangle{
          width: 18px;
          height: 34px;
          background: linear-gradient(176deg, #3CC5EE 0%, #0C0F67 100%);
          border-radius: 1px;
          transform: skewX(-20deg);
          margin-right: 6px;
        }
      }
    }
    .header-class{
      padding: 6px 40px 24px 40px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      .selectClassBtn{
        margin-right: 24px;
        background: #1D2088;
        color: #FFFFFF;
        font-size: 14px;
        padding: 12px 29px;
        border: none;
      }
      span{
        color: #FFFFFF;
        font-size: 14px;
        margin-right: 30px;
      }
    }
  }
  .spectaculars-chart-box{
    padding: 46px 40px 20px;
    display: flex;
    height: calc(100vh - 94px);
    .chart-left, .chart-middle, .chart-right{
      display: flex;
      flex-direction: column;
    }
    .chart-left{
      width: 24%;
    }
    .chart-middle{
      margin: 0 12px;
      flex: 1;
      width: 1%;
      color: #fff;
      display: flex;
      flex-direction: column;
      .middle-header{
        display: flex;
        flex-direction: column;
        align-items: center;
        .middle-header-title{
          span{
            font-size: 20px;
            color: #fff;
            font-weight: bold;
            text-shadow: 0px 0 20px rgba(255,255,255,0.5);
            line-height: 1;
          }
        }
        .middle-header-total{
          width: 100%;
          height: 214px;
          display: flex;
          line-height: 1;
          align-items: center;
          font-size: 30px;
          justify-content: center;
          background: url("./../../assets/img/spectaculars/zheng.png") no-repeat;
          background-size:100% 100%;
          strong{
            font-size: 50px;
          }
        }
      }
      .middle-footer-table{
        display: flex;
        flex: 1;
        height: 1%;
        border-radius: 4px;
        flex-direction: column;
        background: rgba(27, 18, 103, 0.5);
        border: 1px solid rgba(67, 154, 255, 0.15);
        box-shadow: 0px 0px 40px 0px rgba(12, 21, 48, 0.8);
        .footer-table-header{
            // display: flex;
            .one{

              display: flex;
              align-items: center;
              justify-content: space-between;
              padding: 0 14px;
              padding-top: 20px;
              box-sizing: border-box;
              .video{
                font-size: 16px;
              color: #fff;
                span{
                  margin-left: 30px;
                    display: inline-block;
                    width: 8px;
                    height: 8px;
                    background: #E93CA7;
                    border-radius: 50%;
                    margin-right: 10px;

}
              }
              .el-date-picker{
                background-color: transparent;
                // ::v-deep .el-date-editor.el-input__inner{
                //   width: 246px;
                // }
                // ::v-deep .el-date-editor{
                //   background-color: pink;
                // }

              }

            }
            .two{
              display: flex;
              justify-content: flex-end;
              .header-item{
              font-size: 16px;
              color: #fff;
              display: flex;
              // width: 11%;
              align-items: center;
              padding: 10px 9px;
              justify-content: center;
              box-sizing: border-box;
              &:nth-child(1){
                padding-right: 140px;

              }
              &:nth-child(2){
                padding-right: 53px;

              }
              &:nth-child(3){
                padding-right: 63px;
              }
              &:nth-child(7){
                padding-right: 17px;
              }
              &:nth-child(8){
                // width: 20%;
                padding-right: 30px;
              }
            }
            }

          }
        .table-scroll-box{
          display: flex;
          flex-direction: column;
          flex: 1;
          height: 1%;
          ::v-deep .el-scrollbar__wrap {
            overflow-x: hidden;
          }
        }
        .footer-table-line{
          display: flex;
          &:nth-child(odd){
            background: linear-gradient(88deg, rgba(51, 78, 193, 0.27) 0%, rgba(24, 15, 97, 0.22) 97%);
          }
          .table-line-item{
            padding: 10px 0;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 10%;
            span{
              text-align: center;
              color: #fff;
              font-size: 14px;
              i{
                font-size: 22px;
                color: #FFAB61;
                background: #fff;
                border-radius: 50%;
                cursor: pointer;
              }
            }
            &:nth-child(6){
              width: 11%!important;
              margin-left: 6px;
            }
            &:nth-child(1){
              width: 30%!important;
              img{
                max-width: 43px;
                max-height: 54px;
                margin-right: 8px;
              }
              .item-right{
                display: flex;
                flex-direction: column;
                span{
                  width: 140px;
                  text-align: left;
                  &:nth-child(2){
                    color: #2B55AA;
                    margin-top: 4px;
                  }
                }
              }
            }
            &:nth-child(3){
              width: 20%;
              display: flex;
              flex-direction: column;
              span{
                width: 120px;
                &:nth-child(2){
                  margin-top: 4px;
                  color: #2B55AA;
                }
              }
            }
          }
        }

      }
    }
    .chart-right{
      width: 27%;
      color: #fff;
      position: relative;
      .timeTab{
        width: 120px;
        display: flex;
        flex-wrap: wrap;
        border-radius: 4px;
        position: absolute;
        top: 14px;
        right: 20px;

        div{
          width: 40px;
          &:nth-child(1){
            border-radius: 4px 0px 0px 4px ;
            margin-bottom: 10px;
          }
          &:nth-child(2){
            border-radius: 0 4px 4px 0 ;
            margin-bottom: 10px;
          }
          &:nth-child(3){
            border-radius: 4px 0px 0px 4px ;
            margin-bottom: 10px;
          }
          &:nth-child(4){
            border-radius: 0 4px 4px 0 ;
            margin-bottom: 10px;
          }
        }
        .timeBtn{
          cursor: pointer;
          padding: 6px 8px;
          background: #0C1530;
          color: #224B84;

          display: inline-block;
        }
        .timeBtnActive{
          cursor: pointer;
          padding: 6px 8px;
          background: #439AFF;
          color: #fff;

        }
      }
      .fans-ranking{
        margin-top: 14px;
        background: rgba(27, 18, 103, 0.5);
        border: 1px solid rgba(67, 154, 255, 0.15);
        box-shadow: 0px 0px 40px 0px rgba(12, 21, 48, 0.8);
        border-radius:8px;
        display: flex;
        flex: 1;
        height: 1%;
        flex-direction: column;
        position: relative;
        .fans-ranking-header{
          padding: 20px 22px;
          display: flex;
          align-items: center;
          span{
            line-height: 1;
            &:nth-child(1){
              width: 8px;
              height: 8px;
              background: linear-gradient(130deg, rgba(233,60,167,0.7), rgba(27, 18, 103, 0.4));
              border-radius: 50%;
              margin-right: 10px;
            }
            &:nth-child(2){
              font-size: 16px;
              color: #fff;
            }
          }
        }
        .fans-ranking-line{
          display: flex;
          color: #fff;
          font-size: 16px;
          margin-bottom: 10px;
          .ranking-line-item{
            text-align: center;
            &:nth-child(1){
              width: 22%;
            }
            &:nth-child(2){
              display: flex;
              width: 56%;
            }
            &:nth-child(3){
              width: 22%;
            }
          }
        }
        .ranking-row-scroll{
          display: flex;
          flex-direction: column;
          flex: 1;
          height: 1%;
          ::v-deep .el-scrollbar__wrap {
            overflow-x: hidden;
          }
        }
        .fans-ranking-row{
          &:nth-child(odd){
            background: linear-gradient(88deg, rgba(51, 78, 193, 0.27) 0%, rgba(24, 15, 97, 0.22) 97%);
          }
          display: flex;
          padding: 10px 0;
          .ranking-row-item{
            display: flex;
            align-items: center;
            &:nth-child(1){
              width: 22%;
              display: flex;
              justify-content: center;
              align-items: center;
              .rankIcon{
                max-width: 17px;
                max-height: 22px;
              }
            }
            &:nth-child(2){
              display: flex;
              width: 56%;
              .ranking-name{
                overflow: hidden;
                display: flex;
                align-items: center;
                img{
                  max-width: 23px;
                  max-height: 23px;
                  border-radius: 50%;
                  margin-right: 10px;
                }
                span{
                  font-size: 14px;
                  color: #FFFFFF;
                }
              }
            }
            &:nth-child(3){
              width: 22%;
              display: flex;
              justify-content: center;
              align-items: center;
              span{
                font-size: 14px;
                color: #FFFFFF;
              }
            }
          }
        }
      }
    }
  }
}

 //教师端选择班级弹框样式
::v-deep.teacherSelectClassBox{
  .classList{
    height: 300px;
    ::v-deep .el-scrollbar__wrap {
      overflow-x: hidden;
    }
  }
  .el-button{
    padding: 9px 36px;
  }
  .el-dialog__body{
    padding: 10px 24px 20px 10px;
  }
  .el-table{
    .el-table__header-wrapper{
      border-radius: 10px;
      .el-checkbox{
        &:after{
          content: '全选';
          font-size: 12px;
          color: #333333;
          margin-left: 9px;
        }
      }
    }
  }
  .is-checked{
    .el-checkbox__inner{
      background-color: #2F54EB;
      border-color: #2F54EB;
    }
  }
  .is-indeterminate{
    .el-checkbox__inner{
      background-color: #2F54EB;
      border-color: #2F54EB;
    }
  }
}
#chartMonthLike,#ageDistribution,#commentTop,#dataAnalyse{
  &:after{
    content: '';
    width: 8px;
    height: 8px;
    background: linear-gradient(130deg, rgba(233,60,167,0.7), rgba(27, 18, 103, 0.4));
    border-radius: 50%;
    position: absolute;
    left: 20px;
    top: 68px;
  }
}

  // color: #217aff;
  //     border: 1px solid #217aff;
      .up:hover {
        border-bottom: 6px solid #217aff;
      }
      .down:hover {
        border-top: 6px solid #217aff;
      }

    .opacity-5 {
      opacity: 1 !important;
     border-bottom: 6px solid #217aff;

    }
    .opacity-1 {
      opacity: 1 !important;
      border-top: 6px solid #217aff;
    }
    .box-icon {
      height: 30px;
      margin-top: 15px;
      margin-left: 5px;
      .up {
        width: 0px; /*设置宽高为0，所以div的内容为空，从才能形成三角形尖角*/
        height: 0px;
        opacity: 0.5;
        border-bottom: 6px solid #a3a5b3;
        border-left: 4px solid transparent; /*transparent 表示透明*/
        border-right: 4px solid transparent;
        margin-bottom: 4px;
      }
      .down {
        width: 0px;
        height: 0px;
        opacity: 0.5;
        border-top: 6px solid #a3a5b3;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
      }
    }
    .box-icon div {
      height: 10px;
      margin-left: 4px;

    }
    .pagination{
      display: flex;
      justify-content: flex-end;
      .el-pagination{
        background-color: transparent;
        color: #fff;
      }

  }

  ::v-deep .btn-prev{
    background-color: transparent;
    color: #fff;
  }
  ::v-deep .el-pager li{
    background-color: transparent;
    color: #fff;
  }
  ::v-deep .el-pager li.active{
    color: #00baff;
  }
  ::v-deep .el-pagination .btn-prev{
    background-color: transparent !important;
    color: #fff !important;
  }
  ::v-deep .el-pagination .btn-next{
    background-color: transparent !important;
    color: #fff !important;

  }
  // ::v-deep .el-icon .el-icon-arrow-righ{
  //   color: #fff;
  //   background-color: #439aff33;
  // }
  ::v-deep .el-input__inner {
	  background-color: transparent !important;
	  border-color: rgba(67, 154, 255, 0.15);
	  // box-shadow: 1px 1px 5px 1px  RGB(128,255,255,0.8) inset;
	  // height: 30px;
	}
  ::v-deep .el-range-input{
    background-color: transparent !important;
    color: #fff;
  }
  ::v-deep .el-range-separator{
    color: #fff;
  }
//   .elDatePicker.el-picker-panel {
//   color: #fff;//设置当前面板的月份的字体为白色，记为1
//   background: #002450;//定义整体面板的颜色
//   border: 1px solid #1384b4;//定义整体面板的轮廓
//   .el-picker-panel__icon-btn {//设置年份月份调节按钮颜色，记为2
//     color: #ffffff;
//    }
//   .el-date-picker__header-label{//设置年月显示颜色，记为3
//   	color: #ffffff;
//   }
//   .el-date-table th {//设置星期颜色，记为4
//       color:#ffffff;
//   }

// }

// 重写表格的样式
.el-table{
  background-color: rgb(22,18,102);
  border: none;
}
.table-container{
  height: 500px;
}
.table-column{
  width: 100%;
  height: 54px;

  display: flex;
  .video-image{
  max-width: 43px;
  max-height: 54px;
  margin-right: 8px;
}
  .item-right{
    display: flex;
    flex-direction: column;
    span{
      width: 140px;
      white-space: nowrap; /* 防止文本换行 */
      overflow: hidden; /* 隐藏超出部分 */
      text-overflow: ellipsis; /* 使用省略号显示超出部分 */
      text-align: left;
      &:nth-child(2){
        color: #2B55AA;
        margin-top: 4px;
      }
    }
  }

}
.go-video{
  text-align: center;
  color: #fff;
  font-size: 14px;
  i{
    font-size: 22px;
    color: #FFAB61;
    background: #fff;
    border-radius: 50%;
    cursor: pointer;
  }
}

::v-deep .el-table thead tr>th{
  width: 100%;
    background-color: rgb(22,18,102);
    border: none;
}
// 更改表格每行背景颜色
:deep(.el-table) tbody tr>td{
    // background-color: rgb(26,28,119);
    color: #fff;
    border: none;
}
// 设置鼠标经过时背景颜色
::v-deep .el-table tr:nth-child(even) {
  background-color: rgb(26,28,119) !important;
}
::v-deep .el-table tr:nth-child(odd) {
  background-color: rgb(22,18,102) !important;
}

::v-deep .el-table--enable-row-hover .el-table__body tr:hover >td{
    background-color:transparent !important;
}

::v-deep .el-table th.el-table__cell.is-leaf {
  border: none !important;
}
::v-deep .el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell{
  background-color: rgb(22,18,102);
}
// ::v-deep .el-table .el-table__body-wrapper tbody tr:nth-child(even) {
//   background-color: #f2f2f2; /* 设置偶数行的背景色 */
// }




div ::v-deep.el-table::before, .el-table--group::after, .el-table--border::after{
  background-color: unset !important;
  border: none !important;
}
.el-table--border tr,td{
       border: none!important;
    }
    .el-table::before{
      height:0;
    }
::v-deep .el-table colgroup col[name='gutter']{
    width: 0px ;
    display: none !important;
}


.el-table {

	 :deep(.el-table__body-wrapper::-webkit-scrollbar) {
	    width: 3px; /*滚动条宽度*/
	    height: 6px; /*滚动条高度*/
	}
	/*定义滚动条轨道 内阴影+圆角*/
	:deep(.el-table__body-wrapper::-webkit-scrollbar-track)  {
	    box-shadow: 0px 1px 3px #071e4a inset; /*滚动条的背景区域的内阴影*/
	    border-radius: 10px; /*滚动条的背景区域的圆角*/
	    background-color: #071e4a; /*滚动条的背景颜色*/
	}
	/*定义滑块 内阴影+圆角*/
	:deep(.el-table__body-wrapper::-webkit-scrollbar-thumb){
	    box-shadow: 0px 1px 3px #595bbb inset; /*滚动条的内阴影*/
	    border-radius: 10px; /*滚动条的圆角*/
	    background-color: rgb(26,28,119); /*滚动条的背景颜色*/
	}
  :deep(.el-table__body-wrapper::-webkit-scrollbar-corner){
  background: rgb(7,30,74);
}
}

.item-right{
  .user-name{
    color: #2B55AA;
  }
}

